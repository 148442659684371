@import "../../styles/colors.scss";
@import "../../styles/mixins/breakpoints.scss";

.landing {
    background-color: $color-background;
    height: 100%;
    min-height: 564px;
    @include tablet {
        min-height: 468px;
    }
}
