$color-white: #FDFFFC;
$color-black: #1E1E24;
$color-panel-primary: #BBC2E2;
$color-panel-accent: #111D4A;
$color-device-primary: #9CEC5B;
$color-device-primary-dark: #5EB715;
$color-device-primary-shadow: #499211;
$color-device-secondary: #FAFA33;
$color-device-secondary-dark: #EFEF06;
$color-background: #AB5BEC;
